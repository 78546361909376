import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import LazyImage from "../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../utils/constants";
import { connect } from "react-redux";
import {
  StyledModal,
  StyledTitle,
  StyledBody,
  DetailInitiativeWrapper,
} from "../../EngagementSectionV2/CompanyInitiative/styles";
import { ProgressBar } from "../../HealthTabV2/HealthTabUpdated/styles";
import { CommonCloseIcon } from "../../../utils/icons";

class CoreValuePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiative: null,
      printCSV: false,
      isloading: false,
      initiativeReportData: [],
    };
  }

  componentDidMount() {
    if (this.props.detailInitiative) {
      this.setState({ initiative: this.props.detailInitiative });
    }
  }

  // Use componentDidUpdate to update the initiative if the prop changes
  componentDidUpdate(prevProps) {
    if (prevProps.detailInitiative !== this.props.detailInitiative) {
      this.setState({ initiative: this.props.detailInitiative });
    }
  }

  showDetailInitiative = () => {
    const { initiative } = this.state;

    window.console.log("initiaitive", initiative);

    if (!initiative) return null;
    return (
      <React.Fragment>
        <div className="row-one">
          <div style={{ display: "flex" }}>
            <div style={{display:"flex",backgroundColor:initiative.color,borderRadius:"4px",justifyContent:"center",alignItems:"center",height:"60px",width:"60px"}}>
              <LazyImage
                style={{ width: "36px", height: "36px" }}
                src={ImageUrl + "/" + initiative.white_icon}
              />
            </div>
            <div className="details">
              <div>
                <span className="name">{initiative.name}</span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span className="points">
                  {"Active"}
                </span>
                <span className="points"> |  +{initiative?.point} {initiative?.point > 0 ? "pts" : "pt"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row-two">
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              className="user-count"
              style={{
                color: initiative.color,
                fontSize: "24px",
                lineHeight: "24px",
                fontFamily: "Rubik-Medium",
              }}
            >
              {initiative.count} Users Received
            </span>{" "}
            <span
              className="user-count"
              style={{
                color: "rgba(0, 92, 135, 0.6)",
                fontFamily: "rubik",
              }} /* style={{color:initiative.font_color}} */
            >
              {" "}
            </span>
          </div>
          <div>
            <span
              className="percent"
              style={{
                color: initiative.color,
                fontSize: "24px",
                lineHeight: "24px",
                fontFamily: "Rubik-Medium",
              }}
            >
              {initiative.percentage}%
            </span>
          </div>
        </div>

        <div className="row-three">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {" "}
            <ProgressBar
              background={"#005C870D"}
              backgroundActive={initiative.color}
              width={initiative.percentage > 100 ? 100 : initiative.percentage}
              height={"20px"}
            >
              <div
                className="progress"
                style={{ paddingRight: "20px", boxShadow: "unset" }}
              ></div>
            </ProgressBar>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { showModal, onClose /*detailInitiative*/ } = this.props;
    return (
      <StyledModal
        transform={1}
        show={showModal}
        newWidth={"600px"}
        newBg={"newBg"}
        height="50px"
        display="none"
      >
        <Modal.Header
          closeButton
          onHide={() => onClose()}
          style={{ background: "white" }}
        >
          <StyledTitle
            style={{
              color: "#005C87",
              width: "100%",
              fontSize: "18px",
              justifyContent: "flex-start",
              marginLeft: "40%",
            }}
          >
            Core Value Info
            <div
              onClick={() => onClose()}
              style={{
                display: "flex",
                margin: "auto 0 auto auto",
                cursor: "pointer",
              }}
            >
              {CommonCloseIcon()}
            </div>
          </StyledTitle>
        </Modal.Header>
        <StyledBody style={{ padding: "24px 24px 16px 24px" }}>
          {/* <StyledTitle color={'color'}>{companyInitiatives && companyInitiatives.length >0?"Select a Company Initiative":"No Comapny Initiatives"}</StyledTitle> */}
          <DetailInitiativeWrapper>
            {this.state.initiative && this.showDetailInitiative()}
          </DetailInitiativeWrapper>
        </StyledBody>
      </StyledModal>
    );
  }
}

CoreValuePopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  detailInitiative: PropTypes.object,
  companyId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  userId: state.profileData.userId,
});

export default connect(mapStateToProps, null)(CoreValuePopup);
