import React, { Component } from "react";
import {
  MainContainer,
  BarGraphDesign,
  DisplayGraphData,
  LineBackground,
} from "./styles";
import { isNull, isUndefined, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Waiting from "../../Waiting";
import { ImageUrl } from "../../../utils/constants";
import CSVList from "../../AttendeesModal/CSVList";
import HoverButton from "../../common/HoverButton";
import TopSection from "../../common/Analytics/TopSection";
import LazyImage from "../../common/LazyImage/LazyImage";
import { DownloadCoreIcon, ViewProfileIcon } from "../../../utils/icons";
import {
  getRecognitionActivityDetails,
  getRecognitionForAllDepartments,
  getRecognitionForAllLocations,
  setSelectedYear,
} from "../../../redux/actions";
// import { FourCardWrapper, NewImgContainerV2, RowV1, RowV2, TotalCountNewV2 } from '../styles';;
import { commaSeperator } from "../../../utils/methods";
import {
  TopCardEngagement,
  TopChallengesWrapper,
} from "../../CompanyEvents/styles";
import { HoverBackground } from "../../EngagementSectionV2/CompanyInitiative/styles";
import CoreValuePopup from "./CoreValuePopup";

class CompanyAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentsIndividualSum: [],
      departmentsTotalSum: 0,
      topDepartmentsDetails: [],
      locationsIndividualSum: [],
      locationsTotalSum: 0,
      topLocationsDetails: [],
      companyId: props.companyInfo["id"],
      open: false,
      openLocation: false,
      buttonIndex: null,
      close: true,
      printDepartmentCSV: false,
      updatedDepartmentCSV: [],
      topDepartmenttype: 0,
      removeCSV: 0,
      displayPercentage: false,
      lineNumber: null,
      printGraphCSV: false,
      updatedGraphCSV: [],
      locationArrayData: [{ label: "Location", key: "LOCATION" }],
      departmentArrayData: [{ label: "Department", key: "DEPARTMENT" }],
      isYearChangedForDepartment: false,
      isYearChangedForLocation: false,
      onHover: -1,
      showMore: false,
      details: null,
    };
  }

  componentDidMount() {
    const { getRecognitionDepartmentDetails, getRecognitionLocationDetails } =
      this.props;
    let locationTotalSum = 0;
    let locationIndividualSum = [];
    let topLocationName = [];
    let depTotalSum = 0;
    let depIndividualSum = [];
    let topDepartmentsName = [];
    if (!isEmpty(getRecognitionDepartmentDetails)) {
      getRecognitionDepartmentDetails.map((data) => {
        depTotalSum =
          depTotalSum +
          data["received_recognition"] +
          data["given_recognition"];
        depIndividualSum.push(
          data["received_recognition"] + data["given_recognition"]
        );
        topDepartmentsName.push(data["department"]);
      });
    }
    if (!isEmpty(getRecognitionLocationDetails)) {
      getRecognitionLocationDetails.map((data) => {
        locationTotalSum =
          locationTotalSum +
          data["received_recognition"] +
          data["given_recognition"];
        locationIndividualSum.push(
          data["received_recognition"] + data["given_recognition"]
        );
        topLocationName.push(data["location"]);
      });
    }
    this.setState({
      departmentsIndividualSum: depIndividualSum,
      departmentsTotalSum: depTotalSum,
      topDepartmentsDetails: topDepartmentsName,
      locationsIndividualSum: locationIndividualSum,
      locationsTotalSum: locationTotalSum,
      topLocationsDetails: topLocationName,
    });
  }

  updateDropdown = (year, id) => {
    const {
      setSelectedYear,
      getRecognitionForAllDepartments,
      getRecognitionForAllLocations,
    } = this.props;
    const { companyId } = this.state;
    setSelectedYear(year);
    getRecognitionForAllDepartments(companyId, year);
    getRecognitionForAllLocations(companyId, year);
    id === "department"
      ? this.setState({
        isYearChangedForDepartment: true,
        isYearChangedForLocation: false,
      })
      : id === "location"? this.setState({
        isYearChangedForLocation: true,
        isYearChangedForDepartment: false,
      }): null;
  };

  printGraphData = (bool) => {
    this.printGraphAction(bool);
  };

  closeDetailPopup = () => {
    this.setState({ showDetailPopup: false, details: null });
  };

  onHoverMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1,
    });
  };

  printGraphAction = (bool) => {
    const { recognitionDetails } = this.props;
    let updatedGraphCSV =
      recognitionDetails &&
      recognitionDetails.length > 0 &&
      recognitionDetails.map((data) => {
        let obj = {};
        obj["NAME"] = data["name"];
        obj["COUNT"] = data["count"];
        obj["PERCENTAGE"] = data["percentage"];
        return obj;
      });
    this.setState({ printGraphCSV: bool, updatedGraphCSV: updatedGraphCSV });
  };

  getBarGraphDesign = () => {
    const { recognitionDetails } = this.props;
    window.console.log("recognitionDetails", recognitionDetails);
    const handleMouseEnter = (index) => {
      this.setState({ lineNumber: index });
      this.setState({ displayPercentage: true });
    };

    const handleMouseLeave = (index) => {
      this.setState({ lineNumber: index });
      this.setState({ displayPercentage: false });
    };

    return (
      <div style={{ marginRight: "65px", width: "100%", height: "100%" }}>
        {recognitionDetails.map(
          (item, index) =>
            index + 1 <= recognitionDetails.length && (
              <DisplayGraphData key={index} style={{ height: "50px" }}>
                <h1>{item.name}</h1>
                <LazyImage src={`${ImageUrl}/${item.icon}`} />
                <div className="y-axis" />
                {item.name ? (
                  <LineBackground
                    linecolor={item.color}
                    percentWidth={item.percentage}
                  >
                    <div
                      className="line"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    />
                    {/* {this.state.displayPercentage && index == this.state.lineNumber && <span className='showPercent'>{((item.count * 20) / 20).toFixed(1)}</span>} */}
                    {this.state.displayPercentage &&
                      index == this.state.lineNumber && (
                      <div className="show-box">
                        <span className="title">Total {item.name} </span>
                        <div>
                          <span className="title">Received :</span>{" "}
                          <span className="value">
                            {" "}
                            {((item.count * 20) / 20).toFixed(0)}
                          </span>
                        </div>
                        <span className="title">Average :</span>
                        <span className="value">
                          {" "}
                          {((item.percentage * 20) / 20).toFixed(0)}%
                        </span>
                      </div>
                    )}
                  </LineBackground>
                ) : null}
              </DisplayGraphData>
            )
        )}
      </div>
    );
  };

  renderBarGraph(recognitionDetails) {
    const { showMore } = this.state;
    const displayedCards = showMore
      ? recognitionDetails
      : recognitionDetails.slice(0, 6);
    const hasMoreThanSixItems = recognitionDetails.length > 6;
    return (
      <BarGraphDesign isMargin={"0px 0px 0px 12px"}>
        <div className="bar-graph-container">
          <div className="title-container">
            <p className="heading">Company Core Values</p>
            <div style={{ marginRight: "15px" }}>
              <HoverButton
                width={"24px"}
                height={"24px"}
                title={"Download"}
                fillOpacity={1}
                disabled={false}
                // isBtnActive={1}
                backgroundColor={"#f0f6f8"}
                backgroundColorActive={"#005c87"}
                activeColor={"#007AB1"}
                inActiveColor={"white"}
                svgPath={DownloadCoreIcon()}
                onClick={() => this.printGraphData(true)}
              />
            </div>
          </div>
          <hr
            className="hr-line"
            style={{
              margin: "10px 12px 8px 12px",
              border: "1px solid rgba(0, 92, 135, 0.30)",
            }}
          />
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                width: "1248px",
                background: "white",
                height: "auto",
                margin: "auto",
                marginBottom: "0px",
                padding:
                  displayedCards?.length > 0 ? "12px 0px 12px 12px" : "0",
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
                borderRadius: "0 0 6px 6px",
              }}
            >
              {/* {wellnessInitiatives !== null && wellnessInitiatives.length > 0 ? wellnessInitiatives.filter((item) => !priorityInitiatives?.initiatives?.some((p) => p.id === item.id)&& !item.is_upcoming).map((item,index) => <div key={index} style={{width:"382px",height:"100px",display:'flex',alignItems:'center',justifyContent:'space-between',padding:'15px',borderRadius:'6px',border:"1px solid #b3cfdb",position:'relative',cursor:"pointer" }} onMouseEnter={() => this.onHoverMouse(item?.id)} onMouseLeave={() => this.onLeaveHoverMouse()} > */}
              {CompanyAnalytics?.length > 0 &&
                displayedCards.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      width: "calc(33.33% - 12px)",
                      height: "84px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "12px",
                      borderRadius: "6px",
                      border: "1px solid rgba(0, 122, 177, 0.3)",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => this.onHoverMouse(index)}
                    onMouseLeave={() => this.onLeaveHoverMouse()}
                  >
                    {item && this.state.onHover === index && (
                      <HoverBackground>
                        <div
                          className="view-button"
                          onClick={() =>
                            this.setState({
                              showDetailPopup: true,
                              details: item,
                            })
                          }
                          onMouseEnter={(e) => {
                            e.target.style.background = "#005C87";
                            e.target.style.color = "#ffff";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.background = "#007AB1";
                            e.target.style.color = "white";
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            <ViewProfileIcon />
                          </div>
                          {"View"}
                        </div>
                      </HoverBackground>
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }} /* onClick={()=>this.setState({showDetailPopup:true,detailInitiative:item})} */
                    >
                      <div
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: item.color,
                        }}
                      >
                        <img
                          src={ImageUrl + "/" + item?.white_icon}
                          alt=""
                          style={{
                            width: "36px",
                            height: "36px",
                            borderRadius: "6px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "240px",
                          display: "flex",
                          flexWrap: "wrap",
                          height: "60px",
                        }}
                      >
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            color: "#005c87",
                            fontFamily: "rubik-medium",
                            fontSize: "16px",
                            marginBottom: 0,
                            textWrap: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            lineHeight: "20px",
                          }}
                        >
                          {item?.name}
                        </p>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            color: "rgba(0, 92, 135, 0.6)",
                            fontFamily: "rubik",
                            fontSize: "16px",
                            marginBottom: 0,
                            lineHeight: "20px",
                          }}
                        >
                          {"Active"} &nbsp;<span className="points">| +{item?.point} {item?.point > 0 ? "pts" : "pt"}</span>
                        </p>
                        
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontFamily: "Rubik-Medium",
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        color: "var(--Navy_blue, #005C87)",
                      }}
                    >
                      {item?.percentage + "%"}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {hasMoreThanSixItems && (
            <div
              style={{
                width: "1242px",
                marginLeft: "2px",
                marginRight: "2px",
                height: "30px",
                flexShrink: 0,
                borderRadius: "0px 0px 6px 6px",
                background: "var(--navy-blue-5, rgba(0, 92, 135, 0.05))",
                textAlign: "center",
              }}
            >
              <button
                onClick={() =>
                  this.setState({ showMore: !this.state.showMore })
                }
                style={{
                  background: "transparent",
                  border: "none",
                  color: "#005C87",
                  fontSize: "14px",
                  cursor: "pointer",
                  padding: "5px 10px",
                  display: "flex",
                  margin: "auto",
                }}
              >
                {this.state.showMore ? "Show Less" : "Show More"}
              </button>
            </div>
          )}
        </div>
      </BarGraphDesign>
    );
  }

  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  showRecognitionDetails = () => {
    const { getRecognitionActivityDetails } = this.props;
    const EngagementEmployeeData = [
      {
        title: "Total Social Post",
        value: "1",
        img: "/images/SocialBox.svg",
        text: "posted",
        color: "rgba(133, 192, 234, 1)",
        total: getRecognitionActivityDetails.social_posts,
        imageBackground: "rgba(133, 192, 234, 0.15)",
        subText: "Posts",
        info: "",
        average:
          getRecognitionActivityDetails?.user_post_posted_percentage +
          " % User" +
          (getRecognitionActivityDetails?.user_post_posted_percentage > 1
            ? "s"
            : ""),
      },
      {
        title: "Recognition Received",
        titleNew: "",
        value: "2",
        img: "/images/TotalRecognitionimg.svg",
        text: "received recognition",
        color: "rgba(220, 148, 181, 1)",
        total: getRecognitionActivityDetails?.received_recognition,
        average:
          getRecognitionActivityDetails?.received_recognition_percentage +
          " % User" +
          (getRecognitionActivityDetails?.received_recognition_percentage > 1
            ? "s"
            : ""),
        imageBackground: "rgba(220, 148, 181, 0.15)",
        subText: "People Received",
        info: "",
      },
      {
        title: "Recognition Given",
        value: "3",
        img: "/images/PeerRecognitionimg.svg",
        text: "gave recognition",
        color: "rgba(118, 171, 120, 1)",
        total: getRecognitionActivityDetails?.gave_recognition,
        average:
          getRecognitionActivityDetails?.gave_recognition_percentage +
          " % User" +
          (getRecognitionActivityDetails?.gave_recognition_percentage > 1
            ? "s"
            : ""),
        imageBackground: "rgba(118, 171, 120, 0.15)",
        subText: "People Gave",
        info: "",
      },
      {
        title: "Exemplifies Company",
        titleNew: "Value",
        value: "4",
        img: "/images/Exempifiesimg.svg",
        text: "received value",
        color: "rgba(175, 135, 193, 1)",
        total: getRecognitionActivityDetails?.exemplifies,
        average:
          getRecognitionActivityDetails?.exemplifies_percentage +
          " % User" +
          (getRecognitionActivityDetails?.exemplifies_percentage > 1
            ? "s"
            : ""),
        imageBackground: "rgba(175, 135, 193, 0.15)",
        subText: "People Exemplified",
        info: "",
      },
    ];
    return EngagementEmployeeData.map((details, index) => (
      <TopCardEngagement
        key={index}
        background={details.imageBackground}
        color={details.color}
        style={{ padding: "12px" }}
      >
        <div className="first-child">
          <div className="image">
            <img
              src={ImageUrl + details.img}
              alt={"engagement-employee"}
              onError={this.fallBack}
            />
          </div>
          <div className="title">
            {details.title}
            <br />
            {details.titleNew}
          </div>
        </div>
        <div className="challengeData">
          <span className="value">{commaSeperator(details?.total)}</span>
          <span className="valueText">{details.subText}</span>
        </div>
        <div
          className="borderCard"
          style={{ width: "100%", margin: "20px 0px" }}
        />
        <div className="last-child">
          <span className="totalParticipant">{details.average}</span>
          <span className="participateText">{details.text}</span>
        </div>
      </TopCardEngagement>
    ));
  };

  render() {
    {
      // window.console.log("recognitionDetails", this.props.recognitionDetails);
    }
    const {
      getRecognitionDepartmentDetails,
      getRecognitionLocationDetails,
      getRecognitionForAllDepartmentsDetails,
      getRecognitionForAllLocationsDetails,
      isLoading,
      recognitionDetails,
      selectedYearInfo,
      yearList,
      getRecognitionActivityDetails,
    } = this.props;
    let finalRecognitionForAllDepartmentsDetails =
      getRecognitionForAllDepartmentsDetails;
    if (
      finalRecognitionForAllDepartmentsDetails &&
      finalRecognitionForAllDepartmentsDetails.length > 0
    ) {
      for (
        let i = 0;
        i < finalRecognitionForAllDepartmentsDetails.length;
        i++
      ) {
        let total = 0;
        for (
          let j = 0;
          j < finalRecognitionForAllDepartmentsDetails[i].data.length;
          j++
        ) {
          total =
            total + finalRecognitionForAllDepartmentsDetails[i].data[j].count;
        }
        let isPresent = finalRecognitionForAllDepartmentsDetails[
          i
        ].data.findIndex((item) => item.recognition === "Total Recognition");
        if (isPresent < 0) {
          finalRecognitionForAllDepartmentsDetails[i].data.unshift({
            recognition: "Total Recognition",
            icon: "social-recognition/thanks_icon.png",
            key: "total",
            count: total,
          });
        }
      }
    }

    let finalRecognitionForAllLocationsDetails =
      getRecognitionForAllLocationsDetails;
    if (
      finalRecognitionForAllLocationsDetails &&
      finalRecognitionForAllLocationsDetails.length > 0
    ) {
      for (let i = 0; i < finalRecognitionForAllLocationsDetails.length; i++) {
        let total = 0;
        for (
          let j = 0;
          j < finalRecognitionForAllLocationsDetails[i].data.length;
          j++
        ) {
          total =
            total + finalRecognitionForAllLocationsDetails[i].data[j].count;
        }
        let isPresent = finalRecognitionForAllLocationsDetails[
          i
        ].data.findIndex((item) => item.recognition === "Total Recognition");
        if (isPresent < 0) {
          finalRecognitionForAllLocationsDetails[i].data.unshift({
            recognition: "Total Recognition",
            icon: "social-recognition/thanks_icon.png",
            key: "total",
            count: total,
          });
        }
      }
    }

    const { printGraphCSV, updatedGraphCSV } = this.state;
    if (
      !isNull(getRecognitionForAllLocationsDetails) &&
      getRecognitionForAllLocationsDetails.length &&
      getRecognitionForAllLocationsDetails
    ) {
      getRecognitionForAllLocationsDetails[0];
    }
    if (
      isNull(getRecognitionDepartmentDetails) ||
      isUndefined(getRecognitionDepartmentDetails) ||
      isNull(getRecognitionLocationDetails) ||
      isUndefined(getRecognitionLocationDetails) ||
      isNull(getRecognitionForAllDepartmentsDetails) ||
      isUndefined(getRecognitionForAllDepartmentsDetails) ||
      isNull(getRecognitionForAllLocationsDetails) ||
      isUndefined(getRecognitionForAllLocationsDetails) ||
      isUndefined(getRecognitionActivityDetails) ||
      isNull(getRecognitionActivityDetails) ||
      isLoading
    ) {
      return <Waiting />;
    }

    const fileTitleGraph = "BarGraphData";
    const csvHeadersGraph = [
      { label: "NAME", key: "NAME" },
      { label: "COUNT", key: "COUNT" },
      { label: "PERCENTAGE", key: "PERCENTAGE" },
    ];

    return (
      <MainContainer>
        <TopChallengesWrapper margin="0 0 40px 0">
          {this.showRecognitionDetails(recognitionDetails)}
        </TopChallengesWrapper>
        {this.renderBarGraph(recognitionDetails)}
        {this.state.showDetailPopup && (
          <CoreValuePopup
            showModal={this.state.showDetailPopup}
            detailInitiative={this.state.details}
            onClose={this.closeDetailPopup}
            companyId={this.state.companyId}
          />
        )}
        {printGraphCSV && (
          <CSVList
            Title={fileTitleGraph}
            removeCSVData={this.printGraphAction}
            headers={csvHeadersGraph}
            data={updatedGraphCSV}
          />
        )}
        <TopSection
          selectedYearInfo={selectedYearInfo}
          yearDataList={yearList}
          onYearSelect={this.updateDropdown}
          isYearChanged={this.state.isYearChangedForDepartment}
          isYearDropdown={true}
          id={"department"}
          title={"Department Peer Recognition"}
          colors={["#85C0EA", "#F1C977", "#F4AAA9"]}
          topData={getRecognitionDepartmentDetails}
          keys={[
            { key: "received_recognition", text: "Recognition Received" },
            { key: "given_recognition", text: "Recognition Given" },
          ]}
          topSectionKey={"department"}
          seeAllTitle={"See all Breakdown"}
          tableData={finalRecognitionForAllDepartmentsDetails}
          tableTitle={"All Departments"}
          tableColumnTitle={"Department"}
          tableColumnKey={"department"}
          tableRowKey={"recognition"}
        />
        <TopSection
          selectedYearInfo={selectedYearInfo}
          yearDataList={yearList}
          onYearSelect={this.updateDropdown}
          isYearChanged={this.state.isYearChangedForLocation}
          isYearDropdown={true}
          id={"location"}
          title={"Location Peer Recognition"}
          colors={["#85C0EA", "#F1C977", "#F4AAA9"]}
          topData={getRecognitionLocationDetails}
          keys={[
            { key: "received_recognition", text: "Recognition Received" },
            { key: "given_recognition", text: "Recognition Given" },
          ]}
          topSectionKey={"location"}
          seeAllTitle={"See all Breakdown"}
          tableData={finalRecognitionForAllLocationsDetails}
          tableTitle={"All Locations"}
          tableColumnTitle={"Location"}
          tableColumnKey={"location"}
          tableRowKey={"recognition"}
        />
      </MainContainer>
    );
  }
}

CompanyAnalytics.propTypes = {
  companyInfo: PropTypes.object,
  getRecognitionDepartmentDetails: PropTypes.array,
  getRecognitionLocationDetails: PropTypes.array,
  getRecognitionForAllDepartmentsDetails: PropTypes.array,
  getRecognitionForAllLocationsDetails: PropTypes.array,
  isLoading: PropTypes.bool,
  recognitionDetails: PropTypes.array,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  setSelectedYear: PropTypes.number,
  getRecognitionForAllDepartments: PropTypes.func,
  getRecognitionForAllLocations: PropTypes.func,
  getRecognitionActivityDetails: PropTypes.array,
};

const mapStateToProps = (state) => ({
  getRecognitionDepartmentDetails:
    state.companyDashboard.getRecognitionDepartmentDetails,
  getRecognitionLocationDetails:
    state.companyDashboard.getRecognitionLocationDetails,
  isLoading: state.companyDashboard.isLoading,
  getRecognitionForAllDepartmentsDetails:
    state.companyDashboard.getRecognitionForAllDepartmentsDetails,
  getRecognitionForAllLocationsDetails:
    state.companyDashboard.getRecognitionForAllLocationsDetails,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  getRecognitionActivityDetails:
    state.companyDashboard.getRecognitionActivityDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  getRecognitionForAllDepartments: (id, year) =>
    dispatch(getRecognitionForAllDepartments(id, year)),
  getRecognitionForAllLocations: (id, year) =>
    dispatch(getRecognitionForAllLocations(id, year)),
  fetchRecognitionActivityDetails: (id, year) =>
    dispatch(getRecognitionActivityDetails(id, year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAnalytics);
